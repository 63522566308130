<template>
  <div ref="rootElement" class="messages">
    <div class="messages__container" t-id="messages">
      <div v-if="allToasts.length > 1" class="messages__close-all">
        <telia-button
          :aria-label="t('a11yCloseAll')"
          variant="secondary"
          size="sm"
          type="button"
          @click="deleteAllMessages"
        >
          {{ t("closeAll") }}
        </telia-button>
      </div>

      <MessagesList :toasts="allToasts" :toasts-limit="displayedToastsLimit" @close="onClose" />

      <div
        t-id="messages-more"
        class="messages__more"
        :class="{
          'messages__more--on-dark': footerIsIntersecting,
        }"
      >
        <Transition name="more">
          <telia-p v-if="oldestToasts.length" disable-hyphenation>
            <strong>{{ t("more", { amount: oldestToasts.length }) }}</strong>
          </telia-p>
        </Transition>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { deleteMessage, deleteAllMessages, subscribe } from "@telia/b2b-message-service";
import type { MessageType } from "@telia/b2b-message-service/@types/types";
import MessagesList from "./MessagesList.vue";
import { translateMixin } from "../locale";

const t = translateMixin.methods.t;

const footerIsIntersecting = ref<boolean>();

let observer: IntersectionObserver;

onMounted(() => {
  const callback = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      footerIsIntersecting.value = entry.isIntersecting;
    });
  };

  observer = new IntersectionObserver(callback, {
    root: null,
    threshold: 0.08,
  });

  const footer = document.querySelector("b2b-footer");
  if (footer) {
    observer.observe(footer);
  }
});

const messages = ref([] as MessageType[]);

const reversedMessages = computed(() => {
  return [...messages.value].reverse();
});

const displayedToastsLimit = 3;

const allToasts = computed(() => {
  return reversedMessages.value.filter((message) => message.type === "toast");
});

const oldestToasts = computed(() => {
  return reversedMessages.value.filter(
    (message, index) => message.type === "toast" && index >= displayedToastsLimit
  );
});

const storeUnsubscribe = subscribe((state) => {
  messages.value = state.messages;
});

const onClose = (id: string) => deleteMessage(id);

onBeforeUnmount(storeUnsubscribe);

onBeforeUnmount(() => {
  observer.disconnect();
});
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/colors/mixins";
@import "@teliads/components/foundations/spacing/tokens";
@import "@teliads/components/foundations/layers/tokens";
@import "@teliads/components/foundations/layers/mixins";
@import "@teliads/components/foundations/motion/tokens";
@import "../assets/shared.scss";

.messages {
  position: fixed;
  inset: $telia-spacing-16;
  @include telia-layer-below($layer-modal);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  pointer-events: none;

  .messages__container {
    position: relative;
    pointer-events: all;
    width: $notification-width;
  }

  &__close-all {
    display: flex;
    justify-content: end;
    margin-bottom: $telia-spacing-16;
  }

  &__more {
    text-align: center;
    height: $telia-spacing-24;

    &--on-dark {
      @include telia-color("white");
    }
  }
}

.more-enter-active,
.more-leave-active {
  transition: all $telia-duration-300 $telia-duration-200 $telia-ease-in-out;
}

.more-enter-from,
.more-leave-to {
  opacity: 0;
  transform: translateY($telia-spacing-24);
}
</style>
