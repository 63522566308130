<template>
  <div t-id="b2b-message-app">
    <Messages />
  </div>
</template>

<script setup lang="ts">
import Messages from "./components/Messages.vue";
import { translateSetup } from "./locale";

translateSetup(["mybusiness"]);
</script>

<style lang="scss" scoped></style>
