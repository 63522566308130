<template>
  <div>
    <TransitionGroup name="list">
      <div
        :key="toast.id"
        v-for="(toast, index) in toasts"
        t-id="notifications-list-item"
        class="notification"
      >
        <Transition name="notification">
          <Toast v-show="overToastLimit(index as number)" v-bind="toast" @close="onClose" />
        </Transition>
      </div>
    </TransitionGroup>
  </div>
</template>

<script setup lang="ts">
import type { MessageType } from "@telia/b2b-message-service/@types/types";
import Toast from "./Toast.vue";

interface Props {
  toasts: MessageType[];
  toastsLimit: number;
}

const props = defineProps<Props>();

const emit = defineEmits(["close"]);

const onClose = (id: string) => emit("close", id);

const overToastLimit = (index: number) => {
  return index < props.toastsLimit;
};
</script>

<style lang="scss" scoped>
@import "@teliads/components/foundations/spacing/tokens";
@import "@teliads/components/foundations/layers/tokens";
@import "@teliads/components/foundations/layers/mixins";
@import "@teliads/components/foundations/motion/tokens";
@import "../assets/shared.scss";

.notification {
  width: $notification-width;
  margin: $telia-spacing-8 $telia-spacing-0;
}

@media (prefers-reduced-motion: no-preference) {
  .list-move,
  .list-enter-active,
  .list-leave-active {
    transition: all $telia-duration-300 $telia-ease-out;
  }

  .list-enter-from,
  .list-leave-to {
    opacity: 0;
    transform: translateX($telia-spacing-128) translateY(calc(-1 * #{$telia-spacing-8}));
  }

  .list-leave-active {
    position: absolute;
  }

  .notification-enter-active,
  .notification-leave-active {
    transition: all $telia-duration-300 $telia-ease-out;
  }

  .notification-enter-from,
  .notification-leave-to {
    opacity: 0;
    transform: translateX($telia-spacing-128);
  }

  .notification-leave-active {
    position: absolute;
  }
}
</style>
