<template>
  <div :id="props.id">
    <telia-notification
      heading-tag="h2"
      :status="props.status"
      :button-aria-label="t('a11yClose')"
      html-aria-live="polite"
      html-role="status"
      variant="inline"
      :button-text="t('close')"
      @vocaClose="emitCloseEvent"
    >
      <span slot="heading"> {{ title }} </span>
      <telia-text-spacing slot="content">
        <telia-p disable-hyphenation
          >{{ body ?? ""
          }}<span v-if="action">
            <br />
            <b2x-sspa-link :href="action.href" disable-visited>
              {{ action.label }}
            </b2x-sspa-link>
          </span>
        </telia-p>
      </telia-text-spacing>
    </telia-notification>
  </div>
</template>

<script setup lang="ts">
import { ToastMessage } from "@telia/b2b-message-service/@types/types";
import { translateMixin } from "../locale";

const t = translateMixin.methods.t;

interface Props {
  id: string;
  body?: string;
  title: string;
  status: ToastMessage["status"];
  action?: {
    href: string;
    label: string;
  };
}

const props = defineProps<Props>();
const emit = defineEmits(["close"]);

const emitCloseEvent = () => emit("close", props.id);
</script>

<style lang="scss" scoped></style>
